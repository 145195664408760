import { InputGroup, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const OneLoginOACSelectApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Select a SAML Application</Title>

    <Text>
      Log in to the OneLogin Admin Dashboard and select "Applications" in the
      navigation bar.
    </Text>

    <Img
      priority
      height={740}
      src="/sso/one-login/v1/onelogin-oac-1.png"
      width={1278}
    />

    <Text>Click "Add App" on the Applications page.</Text>

    <Img
      priority
      height={740}
      src="/sso/one-login/v1/one-login-1.png"
      width={1278}
    />

    <Text>
      Search and select the app that you're connecting with from the App list.
    </Text>

    <Img height={740} src="/sso/one-login/v1/onelogin-oac-2.png" width={1278} />

    <Text>
      Click "Save" to complete adding the {appName} app to your application
      list.
    </Text>

    <Img height={740} src="/sso/one-login/v1/one-login-3.png" width={1278} />

    <Confirm
      label="I’ve selected a SAML Enterprise Application"
      onClick={onNextStep}
    />
  </Article>
);

export const OneLoginOACConfigureCustomerId: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Configure Customer ID in OneLogin</Title>

    <Text>Click the “Configuration” tab from the left sidebar.</Text>

    <CopyInput
      label="Copy the unique ID from this ACS URL"
      value={connection?.saml_acs_url}
    />

    <Text>
      Submit the “Customer ID” in the Application details and click "Save".
    </Text>

    <Text>
      Paste only the customer ID which is the customer specific string at the
      end of the ACS URL.
    </Text>

    <Img height={740} src="/sso/one-login/v1/onelogin-oac-3.png" width={1278} />

    <Confirm
      label="I’ve completed the Customer ID Configuration."
      onClick={onNextStep}
    />
  </Article>
);

export const OneLoginOACUploadCertificate: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, errors, onFileInput, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Upload X.509 Certificate</Title>

    <Text>Click the “SSO” tab from the left sidebar.</Text>

    <Img
      priority
      height={740}
      src="/sso/one-login/v1/one-login-11.png"
      width={1278}
    />

    <Text>Click “View Details” under the X.509 Certificate.</Text>

    <Text>Clicking will open a certificate configuration page.</Text>

    <Img height={740} src="/sso/one-login/v1/one-login-12.png" width={1278} />

    <Text>Download the X.509 Certificate and upload it below.</Text>

    <Card>
      <Form
        disabled={!connection?.saml_x509_certs}
        isUpdate={!!errors?.saml_x509_certs}
        onSubmit={onNextStep}
      >
        <FileField
          error={errors?.saml_x509_certs}
          label="X.509 Certificate"
          name="saml_x509_certs"
          onUpload={onFileInput}
          value={connection?.saml_x509_certs?.[0]}
        />
      </Form>
    </Card>
  </Article>
);

export const OneLoginOACProvideEndpoint: React.FC<
  Readonly<ConnectionStepProps>
> = ({
  connection,
  errors,
  isLoading,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <Article>
    <Title className="mb-4">Step 4: Provide SAML 2.0 Endpoint</Title>

    <Text>
      Navigate back to the “SSO” tab in your Application and copy the SAML 2.0
      Endpoint.
    </Text>

    <Img
      priority
      height={740}
      src="/sso/one-login/v1/one-login-13.png"
      width={1278}
    />

    <Card>
      <Form
        disabled={!connection?.saml_idp_url}
        isLoading={isLoading}
        onSubmit={onNextStep}
      >
        <InputGroup
          autoFocus
          error={
            errors?.saml_idp_url?.message ||
            validationErrors?.saml_idp_url?.message
          }
          id="saml_idp_url"
          label="SAML 2.0 Endpoint"
          name="saml_idp_url"
          onChange={onInputChange}
          placeholder="https://foocorp.onelogin.com/trust/saml2/http-post/sso/..."
          value={connection?.saml_idp_url ?? undefined}
        />
      </Form>
    </Card>
  </Article>
);
