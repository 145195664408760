import { InputGroup, Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';
import { useFeature } from 'utils/feature-flags';

export const PingOneCreateIntegration: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Create SAML Integration</Title>

    <Text>On the "Applications" tab, click "+".</Text>

    <Img
      priority
      height={184}
      src="/sso/ping-one/v1/ping-one-1-updated.png"
      width={830}
    />

    <Text>
      Select "Advanced Configuration" and choose the "SAML" connection type.
    </Text>

    <Img
      height={325}
      src="/sso/ping-one/v1/ping-one-2-updated.png"
      width={830}
    />

    <Text>
      Give it a descriptive "Application Name" to easily identify it later such
      as {appName}-{connection?.name}. Then fill out the "Application
      Description."
    </Text>

    <Img
      height={236}
      src="/sso/ping-one/v1/ping-one-3-updated.png"
      width={830}
    />

    <Text>Click "Continue to Next Step".</Text>

    <Confirm
      label={
        'I\'ve finished creating the SAML Integration and clicked "Continue to Next Step".'
      }
      onClick={onNextStep}
    />
  </Article>
);

export const PingOneSubmitAcsUrl: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <Article>
    <Title className="mb-4">Step 2: Submit ACS URL and Entity ID</Title>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <CopyInput label="Copy this Entity ID" value={connection?.saml_entity_id} />

    <Text>
      Submit ACS URL as the "Assertion Consumer Service (ACS) URL" and the
      Entity ID as the "Entity ID".
    </Text>

    <Img
      height={442}
      src="/sso/ping-one/v1/ping-one-4-updated.png"
      width={830}
    />

    <Text>Click "Continue to Next Step".</Text>

    <Confirm
      label={
        'I\'ve configured the Assertion Consumer Service (ACS) URL and Entity ID and clicked "Continue to Next Step".'
      }
      onClick={onNextStep}
    />
  </Article>
);

export const PingOneConfigureAttributeStatements: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Configure Attribute Statements</Title>

    <Text>
      On the "SSO Attribute Mapping" step, click on the "Add new attribute"
      button to add new attributes to map.
    </Text>

    <Img
      priority
      height={477}
      src="/sso/ping-one/v1/ping-one-5-updated.png"
      width={830}
    />

    <Text>
      Fill in the following Attribute Statements and click “Continue to Next
      Step”:
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label={
        'I\'ve finished configuring the Attribute Statements and clicked "Continue to Next Step".'
      }
      onClick={onNextStep}
    />
  </Article>
);

export const PingOneUploadMetadata: React.FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => {
  const {
    connection,
    errors,
    isLoading,
    onFileInput,
    onInputChange,
    onNextStep,
    validationErrors,
  } = connectionStepProps;

  const isInputSamlCertificateMetadataUrlEnabled = useFeature(
    'inputSamlCertificateMetadataUrl',
  );

  if (!isInputSamlCertificateMetadataUrlEnabled) {
    return (
      <Article>
        <Title className="mb-4">
          Step 4: Upload Identity Provider Metadata
        </Title>

        <Text>
          At the end of the Application Setup Wizard, you'll be given an
          overview of your Identity Provider metadata. Copy the "Initiate Single
          Sign-On (SSO) URL" to use as the Identity Provider Single Sign-On URL
          below.
        </Text>

        <Text>
          Download the "Signing Certificate" to use as the X.509 Certificate
          below.
        </Text>

        <Img
          height={498}
          src="/sso/ping-one/v1/ping-one-6-updated.png"
          width={830}
        />

        <Card>
          <Form
            disabled={
              !connection?.saml_idp_url ||
              !connection?.saml_x509_certs ||
              validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
            }
            isLoading={isLoading}
            isUpdate={!!(errors?.saml_idp_url || errors?.saml_x509_certs)}
            onSubmit={onNextStep}
          >
            <InputGroup
              autoFocus
              error={
                errors?.saml_idp_url?.message ||
                validationErrors?.saml_idp_url?.message
              }
              id="saml_idp_url"
              label="1. Identity Provider Single Sign-On URL"
              name="saml_idp_url"
              onChange={onInputChange}
              placeholder="https://sso.connect.pingidentity.com/sso/sp/initsso..."
              value={connection?.saml_idp_url ?? undefined}
            />

            <FileField
              error={errors?.saml_x509_certs}
              label="2. X.509 Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />
          </Form>
        </Card>
      </Article>
    );
  }

  return (
    <MetadataUrlStep
      {...connectionStepProps}
      idpEntityIdPlaceholder="https://sso.connect.pingidentity.com/sso/sp/initsso..."
      idpSsoUrlPlaceholder="https://sso.connect.pingidentity.com/sso/sp/initsso..."
      metadataUrlPlaceholder="https://auth.pingone.com/438a423-0052-4234-a3e1-37244316b20b/saml20/metadata/bcd06244-183d-4534-89c5-acbbbc65b53e"
      stepTitle="Step 4: Upload Metadata from PingOne"
    >
      <Text>
        At the end of the Application Setup Wizard, you'll be given an overview
        of your Identity Provider metadata. Copy the "Initiate Single Sign-On
        (SSO) URL" to use as the Identity Provider Single Sign-On URL below.
      </Text>

      <Img height={600} src="/sso/ping-one/v1/ping-one-7.png" width={830} />

      <Text>Paste the Metadata URL in the field below.</Text>
    </MetadataUrlStep>
  );
};
