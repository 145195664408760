import { InputGroup, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';
import { useFeature } from 'utils/feature-flags';

export const KeycloakSamlCreateClient: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Create SAML Client</Title>

    <Text>
      Log in to your Keycloak Admin Console, and navigate to the Realm you want
      to set up the SAML client in. Select "Clients" from the side menu and then
      select "Create".
    </Text>

    <Img
      priority
      height={970}
      src="/sso/keycloak-saml/v1/keycloak-saml-1.png"
      width={3326}
    />

    <Text>
      Copy the Client ID and Client SAML Endpoint below and input them on the
      Add Client setup page.
    </Text>

    <CopyInput label="Copy this Client ID" value={connection?.saml_entity_id} />

    <CopyInput
      label="Copy this Client SAML Endpoint"
      value={connection?.saml_acs_url}
    />

    <Img
      height={786}
      src="/sso/keycloak-saml/v1/keycloak-saml-2-ap.png"
      width={1642}
    />

    <Confirm label="I've created a SAML Client" onClick={onNextStep} />
  </Article>
);

export const KeycloakSamlConfiguration: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: SAML Client Configuration</Title>

    <Text>
      On the Settings page, scroll down and make sure "Sign Assertions" is
      turned ON, and "Client Signature Required" is turned OFF.
    </Text>

    <Text>
      Copy the Redirect URI below and enter it in the "Valid Redirect URIs" box.
      This is the same as the Client SAML Endpoint from the previous step.
      Scroll down and click "Save".
    </Text>

    <CopyInput
      label="Copy this Redirect URI"
      value={connection?.saml_acs_url}
    />

    <Img
      priority
      height={1502}
      src="/sso/keycloak-saml/v1/keycloak-saml-3-ap.png"
      width={1634}
    />

    <Confirm
      label="I've completed my SAML Client Configuration."
      onClick={onNextStep}
    />
  </Article>
);

export const KeycloakSamlUserAttributes: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Map User Attributes</Title>

    <Text>Click the "Mappers" top menu option. Select "Create".</Text>

    <Img
      priority
      height={662}
      src="/sso/keycloak-saml/v1/keycloak-saml-4.png"
      width={2458}
    />

    <Text>
      You'll need to create a "User Property" mapper for the following four
      attributes: "id", "email", "firstName", and "lastName".
    </Text>

    <Text>This is an example of how to fill out the fields for "id":</Text>

    <Img
      priority
      height={1042}
      src="/sso/keycloak-saml/v1/keycloak-saml-5.png"
      width={1668}
    />

    <Text>
      Also do this for the "email", "firstName", and "lastName" attributes:
    </Text>

    <Img
      priority
      height={1022}
      src="/sso/keycloak-saml/v1/keycloak-saml-6.png"
      width={1672}
    />

    <Img
      priority
      height={1042}
      src="/sso/keycloak-saml/v1/keycloak-saml-7.png"
      width={1668}
    />

    <Img
      priority
      height={1042}
      src="/sso/keycloak-saml/v1/keycloak-saml-8.png"
      width={1668}
    />

    <Confirm label="I've mapped the user attributes." onClick={onNextStep} />
  </Article>
);

export const KeycloakSamlMetadataUpload: React.FC<
  Readonly<ConnectionStepProps>
> = (connectionStepProps) => {
  const {
    connection,
    errors,
    isLoading,
    onFileInput,
    onInputChange,
    onNextStep,
    validationErrors,
  } = connectionStepProps;

  const isInputSamlCertificateMetadataUrlEnabled = useFeature(
    'inputSamlCertificateMetadataUrl',
  );

  if (!isInputSamlCertificateMetadataUrlEnabled) {
    return (
      <Article>
        <Title className="mb4">Step 4: IdP Metadata</Title>

        <Text>
          Select "Realm Settings" in the left sidebar navigation menu, and
          select the "SAML 2.0 Identity Provider Metadata" link on the General
          page.
        </Text>

        <Img
          priority
          height={1404}
          src="/sso/keycloak-saml/v1/keycloak-saml-9.png"
          width={3000}
        />

        <Text>
          In the resulting metadata, copy the "Location" from the
          'SingleSignOnService
          Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST"' tag. This is
          the IdP SSO URL that you'll input below. It'll take the pattern of
          "http://mykeycloak.com/auth/realms/myrealm/protocol/saml".
        </Text>

        <Text>
          Next, navigate to the "Keys" top menu option. Here select the
          "Certificate" for the RSA256 algorithm. You'll need to copy this
          certificate and convert it to a PEM file. Once you convert it, you can
          upload the file below.
        </Text>

        <Card>
          <Form
            disabled={
              !connection?.saml_idp_url ||
              !connection?.saml_x509_certs ||
              validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
            }
            isLoading={isLoading}
            isUpdate={!!(errors?.saml_idp_url || errors?.saml_x509_certs)}
            onSubmit={onNextStep}
          >
            <FileField
              error={errors?.saml_x509_certs}
              label="Certificate (Base64)"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />

            <InputGroup
              autoFocus={true}
              id="saml_idp_url"
              label="Login URL"
              name="saml_idp_url"
              onChange={onInputChange}
              placeholder="http://mykeycloak.com/auth/realms/myrealm/protocol/saml"
              value={connection?.saml_idp_url ?? undefined}
            />
          </Form>
        </Card>
      </Article>
    );
  }

  return (
    <MetadataUrlStep
      {...connectionStepProps}
      idpEntityIdPlaceholder="https://mykeycloak.com/123-abc/"
      idpSsoUrlPlaceholder="http://mykeycloak.com/auth/realms/myrealm/protocol/saml"
      metadataUrlPlaceholder="https://mykeycloak.com/auth/realms/myrealm/protocol/saml/descriptor"
      stepTitle="Step 4: Upload IdP Metadata"
    >
      <Text>
        Select "Realm Settings" in the left sidebar navigation menu, and copy
        the "SAML 2.0 Identity Provider Metadata" link on the General page, and
        input it below.
      </Text>

      <Img
        priority
        height={1404}
        src="/sso/keycloak-saml/v1/keycloak-saml-9.png"
        width={3000}
      />
    </MetadataUrlStep>
  );
};
