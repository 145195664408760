import { InputGroup, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Link } from 'components/link';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';
import { AdpCertUploadForm } from './adp-oidc-form/configure-adp-cert';

export const AdpOidcCreateProject: React.FC<Readonly<ConnectionStepProps>> = ({
  onNextStep,
}) => (
  <Article>
    <Title className="mb-4">
      Step 1: Create a Project in the ADP Apps Portal
    </Title>

    <Text>
      Login to the{' '}
      <Link
        newTab
        appearance="highlight"
        aria-label="Link to the ADP Apps Portal"
        href="https://adpapps.adp.com/self-service/projects"
      >
        ADP Partner Self Service Portal
      </Link>
    </Text>

    <Text>
      From this page there are two environments to select from: Development and
      Production. Please use the environment that best suits your use-case for
      this SSO connection.
    </Text>

    <Img
      priority
      height={710}
      src="/sso/adp-oidc/v1/adp-oidc-1.png"
      width={1000}
    />

    <Text>In the selected environment select "Create New Project".</Text>

    <Img
      priority
      height={710}
      src="/sso/adp-oidc/v1/adp-oidc-2.png"
      width={1000}
    />

    <Text>
      Give the project a meaningful name to designate the SSO connection. There
      is no need to add a description.
    </Text>

    <Img
      priority
      height={710}
      src="/sso/adp-oidc/v1/adp-oidc-3.png"
      width={1000}
    />

    <Text>
      Make the selections "US Marketplace" and "ADP Workforce Now" respectively
      for the next selections and then click "Next".
    </Text>

    <Img
      priority
      height={810}
      src="/sso/adp-oidc/v1/adp-oidc-4.png"
      width={900}
    />

    <Text>
      Finally, select "Other" for the use case that best describes your
      application and click "Submit".
    </Text>

    <Confirm
      label="I’ve created a new application in ADP."
      onClick={onNextStep}
    />
  </Article>
);

export const AdpOidcConfigureRedirectUri: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Configure the Redirect URI in ADP</Title>

    <Text>
      In this step you'll navigate to the SSO configurations in ADP and add the
      Redirect URI provided below.
    </Text>

    <Img
      priority
      height={500}
      src="/sso/adp-oidc/v1/adp-oidc-5.png"
      width={800}
    />

    <CopyInput
      label="Copy this Redirect URI"
      value={connection?.oidc_redirect_uri}
    />

    <Text>
      Now that a new project has been created browse to the "Development API
      Credentials" Tab within the project.
    </Text>

    <Text>Click on the "End-User/SSO sub-tab" from this view.</Text>

    <Text>
      Paste the Redirect URI into the App redirect URI field and click "Update
      Redirect".
    </Text>

    <Confirm
      label="I’ve added the Redirect URI into ADP."
      onClick={onNextStep}
    />
  </Article>
);

export const AdpOidcProvideClientId: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, errors, isLoading, onInputChange, onNextStep }) => (
  <Article>
    <Title className="mb-4">
      Step 3: Provide the Client ID and Secret from ADP
    </Title>

    <Text>
      Next, under the same tab used in the previous step, you'll provide the
      Client ID and Client Secret from ADP.
    </Text>

    <Img
      priority
      height={800}
      src="/sso/adp-oidc/v1/adp-oidc-6.png"
      width={1250}
    />

    <Text>
      Simply click to reveal the secret and copy and paste both the client ID
      and client secret into the fields below.
    </Text>

    <Card>
      <Form
        disabled={
          !connection?.oidc_client_id || !connection?.oidc_client_secret
        }
        isLoading={isLoading}
        isUpdate={!!(errors?.oidc_client_id || errors?.oidc_client_secret)}
        onSubmit={onNextStep}
      >
        <InputGroup
          autoFocus
          error={errors?.oidc_client_id?.message}
          id="oidc_client_id"
          label="Client ID"
          name="oidc_client_id"
          onChange={onInputChange}
          value={connection?.oidc_client_id ?? undefined}
        />

        <InputGroup
          error={errors?.oidc_client_secret?.message}
          id="oidc_client_secret"
          label="Client Secret"
          name="oidc_client_secret"
          onChange={onInputChange}
          value={connection?.oidc_client_secret ?? undefined}
        />
      </Form>
    </Card>
  </Article>
);

export const AdpOidcUploadCertificate: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">
      Step 4: Upload the SSL Certificate and Private Key from ADP
    </Title>

    <Text>
      ADP uses a two fold certificate method with an SSL certificate and an SSL
      Private Key. The SSL Private Key is only displayed one time when the
      certificate is generated. If the certificate has already been generated,
      the IT Administrator who generated it should have also received the
      Private Key otherwise a new certificate and key can be generated by
      browsing to the certificates tab on the left hand navigation.
    </Text>

    <Text>
      You can download the SSL Certificate from ADP by browsing to 'Certificate'
      on the left hand nav bar. You can also create a new SSL Certificate and
      Private Key pair if necessary.
    </Text>

    <Img
      priority
      height={642}
      src="/sso/adp-oidc/v1/adp-oidc-7.png"
      width={1216}
    />

    <AdpCertUploadForm
      connectionId={connection?.id || ''}
      onNextStep={onNextStep}
    />
  </Article>
);
